export const header = `
  
    <h1>Premium Pixels & Exclusive Domains</h1>
    <p>
    Take advantage of GiddyUp's proprietary solution that provides Marketing Partners with the most accurate and advanced pixel firing available – stop worrying about tracking issues and focus on what you do best! With our revamped Exclusive Domains you can take advantage of faster page load speeds, automated image optimization, support for Advertorial, VSL, & Listicle domains. Plus with direct linking you can now avoid destination URL mismatches.
    </p>
    <p>
    UPDATE: Feb 2025<br/>
    AppLovin tracking is live! It's fully integrated into our Premium Pixel solution and can be used with all Exclusive Domains!
    </p>
    <p>
    Reminder - All legacy Exclusive Domains are no longer supported as of Feb 12, 2025. You must migrate to the new Exclusive Domains to avoid any interruption to your traffic.
    </p>
    <p>
    <a target="_blank" href="https://helpcenter.giddyup.io/en/collections/10701434-premium-pixels-exclusive-domains">Learn more about the changes here.</a>
    </p>
    <p>
    To gain access to the new Exclusive Domains, please contact your GiddyUp Account Manager!
    </p>
`;